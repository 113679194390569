/*! ==============================================================================
0. Root - CSS Variables
=============================================================================== */
:root {
    /*! Color - Brand */
    --color-primary: #0e222f;
    --color-secondary: #0e222f;
    --color-primary-hover: #0a1a23;
    --color-primary-selected: rgba(14, 34, 47, .12);
    --background-dark-theme: rgba(255, 255, 255, .1);
    --background-dark-theme-hover: rgba(255, 255, 255, .2);
    --bs-danger: #dc3545;
}

/* Login page */
.login-page {
    background-image: url(../public/dist/img/onboardinggridtop.png),
        url(../public/dist/img/onboardinggridbottom.png);
    background-position: center top, center bottom;
    background-repeat: repeat-x;
    background-color: var(--color-primary);
}
.login-box { 
    min-width: 435px;
}
.btn-primary {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}
/* Login page */

/* LoadingButton */
.MuiLoadingButton-loadingIndicator {
    color: white !important;
}
/* LoadingButton */

/* SideNav */
.brand-link .brand-image {
    float: none;
    max-height: 30px;
}
.layout-fixed .brand-link {
    text-align: center;
}
/* SideNav */

/* MUI DataGrid */
.Mui-even {
    background-color: rgba(0,0,0,.05);
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
    font-size: 16px;
    font-weight: 700 !important;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.MuiTablePagination-displayedRows {
    margin-top: 14px;
}

.MuiDataGrid-overlayWrapper {
    text-align: center;
}
/* MUI DataGrid */

/* Loading ***********/
.loading-speeding-wheel {
    border: 2px solid #fff;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: loading-spin 575ms infinite linear;
    -webkit-animation: loading-spin 575ms infinite linear;
}

.loading-speeding-wheel.dark-gray-loading{
    border: 2px solid #d3d3d3;
    border-left-color: transparent;
    border-right-color: transparent;
    margin-left: 20px;
}

.loading-speeding-wheel.medium{
    width: 100px;
    height: 100px;
}

@keyframes loading-spin {
    100%{ transform: rotate(360deg); transform: rotate(360deg); }
}

@-webkit-keyframes loading-spin {
    100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.loading-container-custom {
    z-index: 2000;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    top: 0;
}

.popuploading-container-custom {
    z-index: 2000;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    color: white;
    top: 0;
}

.popuploading-container-custom .loading-contents {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.popuploading-container {
    top: 25%;
}

.loading-container-custom .loading-contents {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-container {
    position: fixed;
    top: 25%;
}
/* Loading ***********/

.margin-top {
    margin-top: 10px;
}

.margin-bottom {
    margin-bottom: 10px;
}

/* List Updating */
.list-updating {
    height: 60px;
    position: relative;
    margin-top: 100px;
}

.list-updating:before {
    -webkit-animation: loading-spin 1s infinite linear, fade 300ms ease;
    animation: loading-spin 1s infinite linear, fade 300ms ease;
    border: 5px solid #ced4da;
    border-radius: 50%;
    border-top-color: #6a7178;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    height: 60px;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    width: 60px;
}
/* List Updating */


.required:after { 
    content:" *";
    color: var(--bs-danger);
    font-weight: lighter;
 }

 /* Modal */
 .modal {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-color: ;
    --bs-modal-bg: var(--bs-body-bg);
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: var(--bs-border-width);
    --bs-modal-border-radius: var(--bs-border-radius-lg);
    --bs-modal-box-shadow: var(--bs-box-shadow-sm);
    --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem 1rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: var(--bs-border-width);
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: var(--bs-border-width);
    display: none;
    height: 100%;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: var(--bs-modal-zindex)
}

 .modal-header .btn-close {
    margin: calc(var(--bs-modal-header-padding-y)*-.5) calc(var(--bs-modal-header-padding-x)*-.5) calc(var(--bs-modal-header-padding-y)*-.5) auto;
    padding: calc(var(--bs-modal-header-padding-y)*.5) calc(var(--bs-modal-header-padding-x)*.5)
}

.btn-close {
    --bs-btn-close-color: #000;
    --bs-btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    box-sizing: initial;
    height: 1em;
    opacity: var(--bs-btn-close-opacity);
    padding: .25em;
    width: 1em;
    margin-top: .1rem !important;
}

.btn-close,.btn-close:hover {
    color: var(--bs-btn-close-color)
}

.btn-close:hover {
    opacity: var(--bs-btn-close-hover-opacity);
    text-decoration: none
}

.btn-close:focus {
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity);
    outline: 0
}

.btn-close.disabled,.btn-close:disabled {
    opacity: var(--bs-btn-close-disabled-opacity);
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none
}

.btn-close-white,[data-bs-theme=dark] .btn-close {
    filter: var(--bs-btn-close-white-filter)
}

.MuiAccordion-root {
    width: 100%;
}

.form-switch .form-check-input {
    width: 3em;
    height: 2em;
}

.btn {
    width: auto;
}